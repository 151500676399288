
section.bg-green {
  background-color: #74F2B7;
  position: relative;
  overflow: hidden;
  padding: 304px 0 221px;

  @media (max-width: 767px) {
    padding-top: 218px;
    padding-bottom: 111px;
  }

  @media (max-width: 575px) {
    padding-bottom: 60px;
    padding-top: 160px;
  }
  
  .eclipse {
    position: absolute;
    bottom: 83%;
    left: -100px;
    width: calc(100% + 200px);
    height: 350px;
    background: #fff;
    -webkit-clip-path: ellipse(50% 10% at 50% 50%);
    clip-path: ellipse(50% 44% at 50% 50%);
  }
}
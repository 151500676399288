.sub-title-other{
  color: #202020;
  font-size: 24px;
  font-weight: 600;
  letter-spacing: 0.6px;
  line-height: 32px;
  margin-bottom: 64px;
}
div.feature-other{
  .feature-card{
    // height: 88px;
    margin-bottom: 33px;

    img{
      padding-top: 8px;
    }
    .feature-other-description{
      padding-left: 7px;
      h4{
        margin-bottom: 6px;
        color: #202020;
        font-size: 18px;
        font-weight: 300;
        letter-spacing: 1px;
        line-height: 24px;
        text-transform: uppercase;
      }
      p{
        color: #676767;
        font-size: 18px;
        font-weight: 300;
        letter-spacing: 0.5px;
        line-height: 26px;

      }
    }
  }
}

 



@media (max-width: 1250px){
  div.feature-other{
    .feature-card{
      .feature-other-description{
        h4{
          font-size: 17px;
          line-height: 24px;
        }
        p{
          font-size: 17px;
          line-height: 26px;
  
        }
      }
    }
  }

}


@media (max-width: 1200px){
  .sub-title-other{
    font-size: 21px;
    line-height: 30px;
    margin-bottom: 40px;
  }
  div.feature-other{
    .feature-card{
      height: 120px;
      margin-bottom: 0;
      img{
        padding-top: 5px;
        transform: scale(0.9)
      }
      .feature-other-description{
        padding-left: 18px;
        h4{
          font-size: 14px;
          line-height: 18px;
        }
        p{
          font-size: 14px;
          line-height: 21px;
        }
      }
    }
  }
}



@media (max-width: 992px){
  .sub-title-other{
    font-size: 18px;
    line-height: 25px;
    margin-bottom: 30px;
  }
  div.feature-other{
    .feature-card{
      height: auto;
      margin-bottom: 30px;

      img{
        padding-top: 5px;
      }
      .feature-other-description{
        h4{
          font-size: 18px;
          line-height: 26px;
          margin-bottom: 13px;
        }
        p{
          font-size: 18px;
          line-height: 25px;
          margin-bottom: 0;
        }
      }

    }
  }
}

@media (max-width: 767px){
  .sub-title-other{
    font-size: 24px;
    line-height: 20px;
    margin-bottom: 75px;
  }
}
footer{
  padding: 27px 0;

  @media (max-width: 575px) {
    padding: 25px 0;
  }

  .logo{
    display: inline-flex;
    position: relative;
    font-size: 32px;
    letter-spacing: 1px;
    font-weight: 600;
    color: #8B8E91;

    @media (max-width: 575px) {
      font-size: 20px;
    }
    
    span {
      padding-left: 10px;

      @media (max-width: 575px) {
        padding-left: 5px;
        position: relative;
        top: 10px;
      }
    }
  
    &:before{
      content: "";
      background: url('./images/logo-gray.svg');
      background-size: contain;
      background-repeat: no-repeat;
      position: relative;
      display: inline-block;
      top: -11px;
      height: 52px;
      width: 52px;

      @media (max-width: 575px) {
        height: 33px;
        width: 33px;
        top: 2px;
      }
      
    }
  }

  .facebookFree{
    text-align: right;

    img{
      height: 71px;
      width: 71px;

      @media (max-width: 575px) {
        width: 44px;
        height: 44px;
      }
    }
  }
}
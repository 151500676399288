section.section-content {

  &.section-problem {
    @media (max-width: 575px) {
      margin-bottom: 50px;
    }
  }
  
  .text{

    h3{
      text-transform: uppercase;
      color: #202020;
      font-size: 16px;
      font-weight: 300;
      letter-spacing: 1px;
      line-height: 22px;
      margin-bottom: 24px;
    }

    .title {
      font-size: 40px;
      font-weight: 600;
      letter-spacing: 1px;
      line-height: 48px;
      color: #202020;
      margin-bottom: 18px;

      @media (max-width: 991px) {
        font-size: 36px;
        line-height: 40px;
      }
      @media (max-width: 575px) {
        font-size: 30px;
        letter-spacing: 0.5px;
        line-height: 40px;
        margin-bottom: 27px;
      }
    }

    p {
      color: #676767;
      font-size: 24px;
      font-weight: 300;
      letter-spacing: 0.5px;
      line-height: 32px;

      @media (max-width: 575px) {
        font-size: 18px;
        line-height: 30px;

        br {
          display: none;
        }
      }
    }
  }

  .gift {
    overflow: hidden;
    
    img {
      width: 100%;
      text-align: center;

      @media (max-width: 767px) {
        width: auto;
        height: 200px;
      }

      @media (max-width: 575px) {
        width: 100%;
        height: auto;
      }
      
    }
  }

  .custom-padding-problem {
    padding-top: 62px;

    @media (max-width: 991px) {
      padding-top: 0;
    }
  }

  .custom-padding-mission {
    padding-top: 134px;

    @media (max-width: 991px) {
      padding-top: 20px;
    }
  }
}
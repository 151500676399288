section.section-devices{
  margin-top: 80px;
  margin-bottom: 15px;
  overflow: hidden;

  @media (max-width: 767px) {
    margin-top: 40px;
    margin-bottom: 0;
  }
  
  @media (max-width: 575px) {
    // display: none;
    max-width: 375px;
    margin: 0 auto;

    padding-top: 30px;
  }
  
  div{
    position: relative;
    height: 535px;

    @media (max-width: 991px) {
      height: 340px;
    }
    @media (max-width: 767px) {
      height: 250px;
    }
    @media (max-width: 575px) {
      height: 140px;
    }

    .image {
      position: absolute;
      top: 0;
      width: 58%;
    }
    
    .laptop{
      left: 0;
    }
    
    .iphone{
      right: 0;
    }
  }
}
.mail-form {

  .title {
    color: #202020;
    font-size: 48px;
    font-weight: 600;
    letter-spacing: 1.2px;
    line-height: 44px;
    text-align: center;
    margin-bottom: 24px;
    transition: .3s ease;

    &.hide {
      opacity: 0;
    }

    @media (max-width: 767px) {
      font-size: 36px;
      line-height: 40px;
      
      br {
        display: none;
      }
    }

    @media (max-width: 575px) {
      font-size: 30px;
      letter-spacing: 0.5px;
    }
  }

  .subtitle {
    color: #202020;
    font-size: 18px;
    font-weight: 300;
    letter-spacing: 0.5px;
    line-height: 26px;
    text-align: center;
    margin-bottom: 39px;

    @media (max-width: 767px) {
      
      br {
        display: none;
      }
    }

    @media (max-width: 575px) {
      padding: 0 25px;
      margin-bottom: 30px;
    }
  }

  form {
    display: inline-block;
    text-align: center;
    position: relative;

    @media (max-width: 991px) {
      display: block;
    }

    input{
      height: 80px;
      width: 759px;
      display: block;
      border-radius: 15px;
      background-color: #FFFFFF;
      border: 2px solid #74F2B7;
      box-shadow: none;
      // color: #757575;
      color: #2b2b2b;
      font-size: 24px;
      font-weight: 300;
      letter-spacing: 0.86px;
      line-height: 80px;
      padding-right: 80px;
      padding-left: 40px;
      transition: .3s ease;

      @media (max-width: 991px) {
        width: 100%;
      }

      &.is-invalid {
        border-color: #f00;
      }

      &:focus{
        outline: none;
      }
    }
    
    button{
      outline: none;
      border: none;
      background: none;
      cursor: pointer;
      font-size: 30px;
      position: absolute;
      top: 50%;
      right: 34px;
      line-height: 1;
      transform: translateY(-50%);
    }
  }

  .content-bottom {
    font-weight: 400;
    color: #202020;
    font-size: 18px;
    letter-spacing: 0.5px;
    margin-top: 88px;

    @media (max-width: 575px) {
      margin-top: 30px;
    }

    a {
      color: inherit;
      position: relative;
      text-decoration: none;
      cursor: pointer;
      display: inline-block;

      &:after {
        content: '';
        position: absolute;
        width: 100%;
        height: 2px;
        background: #000;
        bottom: -2px;
        left: 0;
      }
    }
  }
}
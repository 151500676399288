div.feature-membership{
  .description{
    img{
      width: 36px;
      margin-bottom: 14px;
      margin-top: 110px;

      @media (max-width: 767px) {
        margin-top: 17px;
      }
    }
    h3{
      color: #202020;
      font-size: 24px;
      font-weight: 600;
      letter-spacing: 0.6px;
      line-height: 32px;
      margin-bottom: 8px;
    }
    p{
      color: #676767;
      font-size: 18px;
      font-weight: 300;
      letter-spacing: 0.5px;
      line-height: 26px;
      margin-bottom: 464px;

      @media (max-width: 767px) {
        margin-bottom: 80px;
      }
    }
  }
  .images{
    position: relative;
    overflow: hidden;

    @media (max-width: 767px) {
      max-width: 378px;
      height: 310px;
    }

    .imgBack{
      position: absolute;
      width: 413px;
      left: 100px;
      top: 0;
      z-index: 1;

      @media (max-width: 991px) {
        width: 300px;
      }
      @media (max-width: 767px) {
        left: 24px;
        top: 15px;
        width: 198px;
      }
    }
    .imgFront{
      position: absolute;
      width: 455px;
      left: 200px;
      top: 114px;
      z-index: 3;

      @media (max-width: 1199px) {
        width: 400px;
      }
      @media (max-width: 991px) {
        left: 150px;
        width: 300px;
      }

      @media (max-width: 767px) {
        left: auto;
        right: 24px;
        top: 74px;
        width: 216px;
      }

    }
  }
}
div.feature-time{
  .description{
    img{
      width: 36px;
      margin-bottom: 14px;
      margin-top: 110px;

      @media (max-width: 767px) {
        margin-top: 21px;
      }
    }
    h3{
      color: #202020;
      font-size: 24px;
      font-weight: 600;
      letter-spacing: 0.6px;
      line-height: 32px;
      margin-bottom: 8px;
    }
    p{
      color: #676767;
      font-size: 18px;
      font-weight: 300;
      letter-spacing: 0.5px;
      line-height: 26px;
      margin-bottom: 490px;

      @media (max-width: 991px) {
        margin-bottom: 200px;
      }
      @media (max-width: 767px) {
        margin-bottom: 40px;
      }
    }
  }
  .images{
    position: relative;
    overflow: hidden;

    @media (max-width: 767px) {
      height: 320px;
      max-width: 378px;
    }
    
    .imgBack{
      position: absolute;
      width: 413px;
      left: 100px;
      top: 0;
      z-index: 1;

      @media (max-width: 1199px) {
        left: 50px;
      }
      @media (max-width: 991px) {
        left: 0;
        width: 350px;
      }
      @media (max-width: 767px) {
        left: 24px;
        width: 198px;
        top: 27px;
      }
    }
    .imgFront{
      position: absolute;
      width: 455px;
      left: 200px;
      top: 114px;
      z-index: 3;

      @media (max-width: 1199px) {
        left: 150px;
      }
      @media (max-width: 991px) {
        left: 100px;
        width: 350px;
      }
      @media (max-width: 767px) {
        top: 82px;
        left: auto;
        right: 24px;
        width: 217px;
      }
    }
  }
}
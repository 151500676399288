header{
  padding-top: 48px;
  margin-bottom: 129px;

  @media (max-width: 575px) {
    padding-top: 43px;
    margin-bottom: 70px;
  }

  .logo{
    font-size: 32px;
    letter-spacing: 1px;
    font-weight: 600;
    position: relative;
    display: inline-flex;

    @media (max-width: 575px) {
      font-size: 19px;
    }
    
    &:before{
      content: "";
      background: url('./images/logo-colour.svg');
      background-size: contain;
      background-repeat: no-repeat;
      height: 52px;
      width: 52px;
      display: inline-block;
      position: relative;
      top: -11px;

      @media (max-width: 575px) {
        height: 33px;
        width: 33px;
      }
    }

    span {
      padding-left: 10px;

      @media (max-width: 575px) {
        padding-left: 7px;
        letter-spacing: 1.5px;
      }
    }
  }
  
  .text {
    font-size: 18px;
    letter-spacing: 0.6px;

    @media (max-width: 575px) {
      // display: none;
      visibility: hidden;
      position: fixed;
      background: #fff;
      top: 0;
      left: 0;
      height: 100vh;
      z-index: 100;
      padding-top: 150px;
      opacity: 0;
      transition: .3s ease;

      &.active {
        opacity: 1;
        // display: block;
        visibility: visible;
      }
    }



    .hide-on-mobile {

      @media (max-width: 575px) {
        display: none;
      }
      
    }

    .comma {
      @media (max-width: 575px) {
        display: none;
      }
    }

    .link {
      background: none;
      border: 0;
      outline: none;
      font-weight: 600;
      position: relative;
      display: inline-block;
      color: #202020;
      cursor: pointer;

      @media (max-width: 575px) {
        font-size: 26px;
        display: block;
      }

      &:hover{
        text-decoration: none;
        color: #202020;
      }

      span {
        display: inline-block;
        position: relative;
        
        &:after{
          content: "";
          position: absolute;
          bottom: 3px;
          left: 0;
          width: 100%;
          display: block;
          height: 2px;
          background-color: #74F2B7;
  
          @media (max-width: 575px) {
            // content: none;
          }
        }
      }
      

    }
  }
}

.hamburger {
  position: fixed;
  width: 23px;
  right: 31px;
  top: 44px;
  z-index: 101;
  display: none;

  @media (max-width: 575px) {
    display: block;
  }

  span {
    display: block;
    background-color: #74f2b7;
    height: 2px;
    transition: .3s ease;
    position: relative;

    & + span {
      margin-top: 6px;
    }

  }
  
  &.active {

    span {
      &:first-child {
        transform: rotate(45deg);
        top: 8px;
      }

      &:nth-child(2) {
        opacity: 0;
      }
    
      &:last-child {
        transform: rotate(-45deg);
        top: -8px;
      }
    }
    
  }

}